<template>
  <div class="home">
    <template v-if="!isPaySuccess">
      <div class="pay">
        <div class="title">商品信息</div>
        <template v-for="goodsStandard in orderData.goodsStandardDetails">
          <div class="item card" :key="goodsStandard.goodsId">
            <div class="card-name">{{ goodsStandard.goodsName }}</div>
            <div class="specification">
              <div>规格</div>
              <div class="lable">{{ goodsStandard.standardName }}</div>
            </div>
            <div class="price">
              <div>价格</div>
              <div class="lable">{{ goodsStandard.currentPrice }}元</div>
            </div>
          </div>
        </template>
        <div class="title mt-10">学生信息</div>
        <div class="item student">
          <div class="row">
            <div class="lable">姓名</div>
            <div class="value">{{ orderData.userName }}</div>
          </div>
          <div class="row">
            <div class="lable">学校</div>
            <div class="value">{{ orderData.schoolName }}</div>
          </div>
          <div class="row">
            <div class="lable">年级</div>
            <div class="value">{{ gradeData[orderData.gradeId] }}</div>
          </div>
          <div class="row">
            <div class="lable">班级</div>
            <div class="value">{{ orderData.className }}</div>
          </div>
        </div>
        <div class="title order mt-10">订单信息</div>
        <div class="item order">
          <div class="row amount">
            <div class="lable">订单金额</div>
            <div class="value">{{ orderData.amount }}元</div>
          </div>
          <div class="row">
            <div class="lable">订单编号</div>
            <div class="value">{{ orderData.orderSequence }}</div>
          </div>
          <div class="row">
            <div class="lable">订单时间</div>
            <div class="value">{{ orderData.createAt }}</div>
          </div>
          <div class="row state">
            <div class="lable">订单状态</div>
            <div class="value">{{ stateData[orderData.orderState] }}</div>
          </div>
        </div>
        <div v-if="orderData.orderState === 1" class="pay-btn" @click="toPay">去付款</div>
      </div>
    </template>
    <template v-else>
      <div class="success">
        <img src="../assets/success.png" alt="" class="success-img">
        <div class="state-name">支付成功</div>
        <div class="text">已开通粤教听说学习卡，如 APP 学习卡信息未更新，请于APP “我的 → 设置”中退出重新登录。</div>
      </div>
    </template>

  </div>
</template>

<script>
import { createPayOrder, orderDetails } from '@/api/home'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  data () {
    return {
      gradeData: {
        101: '一年级',
        102: '二年级',
        103: '三年级',
        104: '四年级',
        105: '五年级',
        0: '六年级',
        1: '七年级',
        2: '八年级',
        3: '九年级',
        4: '高一',
        5: '高二',
        6: '高三',
        7: '初中',
        8: '高中'
      },
      orderData: {},
      stateData: {
        0: '已删除',
        1: '未支付', // 已下单未付款
        2: '已支付', // 已付款下单成功
        3: '已取消',
        4: '非法支付',
        5: '已退款'
      },
      isPaySuccess: false,
      orderId: null,
      code: '',
      appId: ''
    }
  },
  created () {
    this.appId = process.env.VUE_APP_BASE_APPID
    this.wxRedirectUri = process.env.VUE_APP_WX_REDIRECT_URI
    // const local = window.location.href
    this.orderId = this.getUrlParam('orderId')
    this.code = this.getUrlParam('code')
    const local = this.wxRedirectUri + '?orderId=' + this.orderId
    if (this.code == null || this.code === '') {
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.appId + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base#wechat_redirect'
    } else {
      this.getOrderDetails(this.orderId)
    }
  },
  methods: {
    // 获取订单详情
    getOrderDetails (id) {
      orderDetails({ orderId: id }).then(res => {
        if (res.state !== 11) return
        this.orderData = res.data
      })
    },
    // 去付款
    toPay () {
      const that = this
      const data = {
        orderId: this.orderId,
        wechatAuthCode: this.code
      }
      // 微信支付订单创建接口 -- 获取微信支付订单接口参数
      createPayOrder(data).then(res => {
        if (res.state !== 11) return
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            appId: this.appId, // 公众号名称，由商户传入
            timeStamp: res.data.timestamp, // 时间戳，自1970年以来的秒数
            nonceStr: res.data.nonceStr, // 随机串
            package: res.data.prepayId,
            signType: res.data.signType, // 微信签名方式：
            paySign: res.data.paySign // 微信签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // alert('支付成功')
              // window.location.reload()
              that.isPaySuccess = true
            } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
              alert('支付已取消')
            } else {
              alert('微信返回异常,如果您已支付请勿再次付款,并返回APP检查学习卡是否已激活:' + res.err_msg)
              // alert(res.err_msg);
            }
          })
      })
    },
    // 解析url
    getUrlParam (name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100vw;

  .pay {
    box-sizing: border-box;
    padding: 0.267rem 0.373rem 0.533rem;
    background: #F5F5F9;

    .title {
      font-size: 0.4267rem;
      font-weight: bold;
      margin-bottom: 0.4rem;
      color: #000000;
    }

    .item {
      margin-bottom: 0.533rem;

      &.card {
        border-radius: 0.32rem;
        width: 9.2533rem;
        height: 3.4133rem;
        box-sizing: border-box;
        padding: 0.587rem 0.373rem;
        background-image: url("../assets/card-bg.png");
        background-size: 9.253rem 3.413rem;
        display: flex;
        flex-flow: column;
        color: #794A1B;

        .card-name {
          font-size: 0.48rem;
          font-weight: bold;
          height: 0.96rem;
          margin-bottom: 0.4rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .specification, .price {
          display: flex;
          align-items: center;

          .lable {
            margin-left: 0.533rem;
            font-size: 0.373rem;
            color: #FE1A13;
          }
        }

        .specification {
          margin-bottom: 0.267rem;
        }

        .price {
          .lable {
            font-weight: bold;
          }
        }
      }

      &.student {
        border-radius: 0.533rem;
        background-color: #ffffff;
        color: #333333;

      }

      &.order {
        margin-bottom: 0;
        border-radius: 0.533rem;
        background-color: #ffffff;
        color: #333333;

        .row {
          justify-content: flex-start;
          font-size: 0.373rem;

          &.amount,&.state{
            .value {
              color: #FE1A13;
            }
          }
          .lable {
            width: 1.867rem;
            margin-right: 0.373rem;
          }
        }
      }

      .row {
        height: 1.493rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.533rem 0.4rem;
        font-size: 0.427rem;
        box-sizing: border-box;
        border-bottom: 0.027rem solid #F5F5F9;

        &:last-child {
          border-bottom: 0;
        }

        .lable {
          font-weight: bold;
        }

        .value {
          color: #597280;
        }
      }
    }

    .pay-btn {
      height: 1.227rem;
      margin-top: 0.8rem;
      color: #ffffff;
      background-color: #1976FF;
      border-radius: 0.613rem;
      text-align: center;
      font-size: 0.427rem;
      line-height: 1.227rem;
    }
  }

  .success {
    background-color: #ffffff;
    display: flex;
    flex-flow: column;
    //justify-content: center;
    align-items: center;
    padding-top: 0.8rem;

    .success-img {
      width: 4.96rem;
      height: 4.053rem;
      margin-bottom: 0.533rem;
    }

    .state-name {
      color: #000000;
      font-size: 0.427rem;
      font-weight: bold;
      margin-bottom: 0.533rem;
    }

    .text {
      width: 7.333rem;
      color: #597280;
      font-size: 0.373rem;
    }
  }
}
</style>
