import request from '@/utils/request'

/**
 * 根据订单id获取订单详情
 * @data {*} data
 */
export function orderDetails (data) {
  return request({
    url: '/apiv2/nfclass/shop/getOrderDetails',
    method: 'post',
    data
  })
}
/**
 * 微信支付订单创建接口
 * @data {*} data
 */
export function createPayOrder (data) {
  return request({
    url: '/apiv2/nfclass/shop/createWxPayOrder',
    method: 'post',
    data
  })
}
