import md5 from 'js-md5'
import _ from 'lodash'
const UUID = require('uuid')

// 设置 uuid
export function generatorUUID () {
  return getGeneratorUUID()
}

// 生成 uuid
function getGeneratorUUID () {
  return _.replace(UUID.v4(), /-/g, '') + md5(localStorage.getItem('userId') + `${+new Date()}`).substring(0, 6)
}
const UUIDKey = 'UUID-key'

export function getUUID () {
  return localStorage.getItem(UUIDKey)
}

export function setUUID (uuid) {
  localStorage.setItem(UUIDKey, uuid)
}

// 生成uuid
export const generateUUID = (len) => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const uuid = []
  const radix = chars.length
  if (len) {
    for (let i = 0; i < len; i += 1) uuid[i] = chars[0 | Math.random() * radix]
  } else {
    // rfc4122, version 4 form
    let r

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (let i = 0; i < 36; i += 1) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16
        uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  return uuid.join('')
}
// export const md () {
//
// }
