import axios from 'axios'
// import store from '@/store'
// import { MessageBox, Message } from 'element-ui'
// import { getToken } from '@/utils/auth'
import md5 from 'js-md5'
import { generateUUID, getUUID, setUUID } from './index'
import MobileDetect from 'mobile-detect'
// import

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 60 // request timeout
})

// const whiteList = ['/login']

// request interceptor -- 请求拦截
service.interceptors.request.use(
  config => {
    let signStr = ''
    signStr = JSON.stringify(config.method === 'get' ? config.params : config.data)
    const md = new MobileDetect(window.navigator.userAgent)
    console.log(md, md.os(), md.mobile())
    const release = '1.0.3' // 版本号
    const device = md.os() // 设备
    const machine = md.mobile() // 型号
    const apps = 501 // 应用
    let udid = getUUID() // 设备唯一标识
    if (!udid) {
      udid = generateUUID(32)
      setUUID(udid)
    }
    const sign = md5(signStr + '_Sts100#@') // 签名
    config.headers = {
      ...config.headers,
      udid,
      sign,
      release,
      device,
      apps,
      machine
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor -- 响应拦截
service.interceptors.response.use(
  response => {
    const { data } = response
    const { note, state } = data
    if (state !== 11) {
      if (state === 55) {
        alert('服务器错误')
      } else {
        const errorText = note || 'Error'
        alert(errorText)
      }
      if (state === 22) {
        alert('你需要重新登录')
      }
      return Promise.reject(new Error(note || 'Error'))
    } else if (data instanceof Blob) {
      return response
    } else {
      return data
    }
  },
  error => {
    console.log('err' + error) // for debug
    const errMsg = error.message.includes('timeout') ? '网络请求超时，请重试' : error.message
    console.log('errMsg', errMsg)
    return Promise.reject(error)
  }
)

export default service
